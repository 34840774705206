import { initMobileMenu } from './mobile-menu/initMobileMenu';
import { toggleMenu } from './mobile-menu/toggleMenu';
import { scrollToElem } from './utils/scrollToElem';
import { initScrollToAnchor } from './utils/initScrollToAnchor';
import { initSwiperAbout } from './utils/initSwiperAbout';
import { initJivoChat } from './components/initJivoChat';

window.addEventListener('DOMContentLoaded', onLoad, false);

function onLoad() {
    initMobileMenu();
    toggleMenu();
    initScrollToAnchor();
    scrollToElem();
    initSwiperAbout();
    initJivoChat();
}
